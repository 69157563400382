import {Result} from '../types/Result';

export const resultsTitle = 'Past Results';

export const resultsSplashData: Result[] = [
    {
        id: 47,
        type: 'Wrongful Death',
        subtype: 'Negligent Supervision',
        amount: 'Confidential Settlement',
        description:
            'A sober home patient overdosed after the sober home hid him in a motel knowing he was using illegal narcotics.',
    },
    {
        id: 0,
        type: 'Wrongful Death',
        subtype: 'Negligent Service',
        amount: 'Confidential Settlement',
        description:
            'A disabled person was mechanically asphyxiated due to a service/repair issue with his motorized wheelchair preventing him from placing it in reverse.',
    },
    {
        id: 1,
        type: 'Wrongful Death',
        subtype: 'Truck Accident',
        amount: '$2,500,000.00',
        description:
            "A tractor trailer driver entered an intersection without maintaining a proper lookout causing a collision with decedent's tractor trailer.",
    },
    {
        id: 2,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: 'Confidential Settlement',
        description:
            "A 4 year-old was catastrophically injured resulting in tetraplegia due to a booster seat's defectively designed and marketed restraint system.",
    },
    {
        id: 3,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: 'Confidential Settlement',
        description:
            'A young adult was catastrophically injured resulting in tetraplegia due to the roof collapse of a truck in which he was a passenger.',
    },
    {
        id: 4,
        type: 'Wrongful Death',
        subtype: 'Truck Accident',
        amount: '$2,250,000.00',
        description:
            "A tractor trailer driver had a medical event causing him to enter oncoming lanes of traffic causing a collisions with decedent's motor vehicle.",
    },
];

export const resultsData: Result[] = [
    {
        id: 66,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: 'Confidential Settlement',
        description: 'A young woman suffered a partial finger amputation after a dangerous facial wand exploded in her hand.'
    },
    {
        id: 67,
        type: 'Personal Injury',
        subtype: 'Premise Liability',
        amount: 'Confidential Settlement',
        description: 'A middle-aged woman incurred a mild traumatic brain injury after a food truck awning collapsed striking her in the head.'
    },
    {
        id: 47,
        type: 'Wrongful Death',
        subtype: 'Negligent Supervision',
        amount: 'Confidential Settlement',
        description:
            'A sober home patient overdosed after the sober home hid him in a motel knowing he was using illegal narcotics.',
    },
    {
        id: 0,
        type: 'Wrongful Death',
        subtype: 'Negligent Service',
        amount: 'Confidential Settlement',
        description:
            'A disabled person was mechanically asphyxiated due to a service/repair issue with his motorized wheelchair preventing him from placing it in reverse.',
    },
    {
        id: 2,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: 'Confidential Settlement',
        description:
            "A 4 year-old was catastrophically injured resulting in tetraplegia due to a booster seat's defectively designed and marketed restraint system.",
    },
    {
        id: 3,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: 'Confidential Settlement',
        description:
            'A young adult was catastrophically injured resulting in tetraplegia due to the roof collapse of a truck in which he was a passenger.',
    },
    {
        id: 49,
        type: 'Wrongful Death',
        subtype: 'Product Liability',
        amount: 'Confidential Settlement',
        description:
            'A 3-month-old was killed due to a defective infant seat belt buckle that broke in an automobile collision.',
    },
    {
        id: 53,
        type: 'Catastrophic Injury',
        subtype: 'Negligence Supervision',
        amount: 'Confidential Settlement',
        description:
            'An autistic young adult dehydrated at a summer camp for special needs adults due to lack of supervision.',
    },
    {
        id: 57,
        type: 'Wrongful Death',
        subtype: 'Negligent Supervision',
        amount: 'Confidential Settlement',
        description:
            'An recent arrestee overdosed in a holding cell while under the supervision of a jail.',
    },
    {
        id: 58,
        type: 'Catastrophic Injury',
        subtype: 'Negligent Service',
        amount: 'Confidential Settlement',
        description: 'A disabled person was injured in a building due to a service issue with a transportation device.',
    },
    {
        id: 59,
        type: 'Catastrophic Injury',
        subtype: 'Negligent Security',
        amount: 'Confidential Settlement',
        description: 'A person was injured in a parking lot that was known to be dangerous.',
    },
    {
        id: 60,
        type: 'Personal Injury',
        subtype: 'Negligent Security',
        amount: 'Confidential Settlement',
        description: 'An elderly person was attacked in a parking lot that had inadequate security.',
    },
    {
        id: 54,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: 'Confidential Arbitration Award',
        description:
            'A young adult incurred severe burns after his new cell phone exploded in his pocket.',
    },
    {
        id: 68,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: 'Policy Limit',
        description: 'A middle-aged woman incurred a fractured hip after another vehicle ran a red light striking the vehicle in which she was a passenger.'
    },
    {
        id: 69,
        type: 'Wrongful Death',
        subtype: 'Vehicle Accident',
        amount: 'Policy Limit',
        description: 'An elderly man was hit by a vehicle as a pedestrian while crossing the roadway resulting in his death.'
    },
    {
        id: 20,
        type: 'Catastrophic Injury',
        subtype: 'Vehicle Accident',
        amount: 'Policy Limit',
        description:
            'A man suffered a leg amputation after being hit by a motor vehicle while walking his dog.',
    },
    {
        id: 25,
        type: 'Wrongful Death',
        subtype: 'Truck Accident',
        amount: 'Policy Limit',
        description:
            'A tractor trailer driver failed to maintain a proper lookout causing a collision with the motor vehicle in which decedent was a passenger.',
    },
    {
        id: 29,
        type: 'Wrongful Death',
        subtype: 'Vehicle Accident ',
        amount: 'Policy Limit ',
        description:
            'A young woman suffered a miscarriage after being struck by a motor vehicle that ran a red light.',
    },
    {
        id: 31,
        type: 'Wrongful Death',
        subtype: 'Vehicle Accident',
        amount: 'Policy Limit',
        description:
            'A young man was killed as passenger in a motor vehicle that was operated carelessly by its driver.',
    },
    {
        id: 50,
        type: 'Catastrophic Injury',
        subtype: 'Truck Accident',
        amount: '$15,750,000.00',
        description:
            'Local counsel for a man whose leg was traumatically amputated by a forklift that was unsafely operated by another.',
    },
    {
        id: 65,
        type: 'Catastrophic Injury',
        subtype: 'Negligent Security',
        amount: '$15,000,000.00',
        description:
            'A young woman suffered catastrophic injury to her face and kidney after being struck by a stray bullet in dangerous parking lot.'
    },
    {
        id: 1,
        type: 'Wrongful Death',
        subtype: 'Truck Accident',
        amount: '$2,500,000.00',
        description:
            "A tractor trailer driver entered an intersection without maintaining a proper lookout causing a collision with decedent's tractor trailer.",
    },
    {
        id: 4,
        type: 'Wrongful Death',
        subtype: 'Truck Accident',
        amount: '$2,250,000.00',
        description:
            "A tractor trailer driver had a medical event causing him to enter oncoming lanes of traffic causing a collisions with decedent's motor vehicle.",
    },
    {
        id: 56,
        type: 'Catastrophic Injury',
        subtype: 'Truck Accident',
        amount: '$1,950,000.00',
        description:
            'A man was injured while working on the side of the road when a truck hit another vehicle off the roadway.',
    },
    {
        id: 5,
        type: 'Negligence',
        subtype: 'Negligent Supervision',
        amount: '$1,300,000.00',
        description:
            "A 6 year-old suffered a brain injury due to a delay in medical attention after the child's special needs transportation driver failed to timely recognize that she was not breathing.",
    },
    {
        id: 6,
        type: 'Catastrophic Injury',
        subtype: 'Dram Shop',
        amount: '$1,100,000.00',
        description:
            "A husband was catastrophically injured by an impaired driver who entered oncoming lanes of traffic after being overserved at a bar causing a collision with the husband's motor vehicle.",
    },
    {
        id: 64,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: '$1,000,000.00',
        description: 'A person was injured due to a defective chair that was inadequately designed and manufactured.',
    },
    {
        id: 51,
        type: 'Catastrophic Injury',
        subtype: 'Negligent Security',
        amount: '$1,000,000.00',
        description:
            'Policy limit settlement for a young woman who was shot in a parking lot at night.',
    },
    {
        id: 7,
        type: 'Property Damage',
        subtype: 'Product Liability',
        amount: '$1,000,000.00',
        description:
            "A family's home was destroyed in a house fire due to improperly installed spray foam insulation.",
    },
    {
        id: 8,
        type: 'Wrongful Death',
        subtype: 'Negligent Security',
        amount: '$950,000.00',
        description:
            "A teenager drowned in a condominium's swimming pool that was not properly secured or monitored.",
    },
    {
        id: 9,
        type: 'Catastrophic Injury',
        subtype: 'Vehicle Accident',
        amount: '$850,000.00',
        description:
            'A young man was catastrophically injured while riding a motorcycle after driver entered an intersection violating his right of way.',
    },
    {
        id: 61,
        type: 'Catastrophic Injury',
        subtype: 'Truck Accident',
        amount: '$750,000.00',
        description: 'A commercial truck driver was injured after being hit by another commercial truck driver while stopped on the roadway for traffic.',
    },
    {
        id: 10,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: '$725,000.00',
        description:
            'A man required multiple knee surgeries due to a defectively manufactured replacement.',
    },
    {
        id: 11,
        type: 'Catastrophic Injury',
        subtype: 'Dram Shop',
        amount: '$575,000.00',
        description:
            'A young man was seriously injured as a passenger in a motor vehicle accident whose driver was overserved at a bar.',
    },
    {
        id: 12,
        type: 'Wrongful Death',
        subtype: 'Civil Rights',
        amount: '$562,500.00',
        description:
            'An unarmed young man with mental health issues was shot and killed by police.',
    },
    {
        id: 13,
        type: 'Catastrophic Injury',
        subtype: 'Truck Accident',
        amount: '$510,000.00',
        description:
            'A young man suffered a mild traumatic brain injury after hitting a tractor trailer that was illegally stopped on the highway.',
    },
    {
        id: 14,
        type: 'Catastrophic Injury',
        subtype: 'Truck Accident',
        amount: '$510,000.00',
        description:
            'A man required spine surgery after being rear-ended on the interstate by a tractor trailer driver who failed to maintain a proper lookout.',
    },
    {
        id: 55,
        type: 'Catastrophic Injury',
        subtype: 'Vehicle Accident',
        amount: '$450,000.00',
        description:
            "A woman was injured by a motor vehicle that jumped a curb because its driver hit the vehicle's gas instead of its brakes.",
    },
    {
        id: 15,
        type: 'Catastrophic Injury',
        subtype: 'Negligent Security',
        amount: '$410,000.00',
        description:
            'A young man suffered a traumatic brain injury after being assaulted in front of a bar.',
    },
    {
        id: 16,
        type: 'Catastrophic Injury',
        subtype: 'Product Liability',
        amount: '$357,000.00',
        description:
            "A 4 year-old suffered a mild traumatic brain injury due a booster seat's defectively designed and marketed restraint system.",
    },
    {
        id: 17,
        type: 'Catastrophic Injury',
        subtype: 'Truck Accident',
        amount: '$337,500.00',
        description:
            'A man required multiple wrist surgeries after being hit by a garbage truck.',
    },
    {
        id: 18,
        type: 'Catastrophic Injury',
        subtype: 'Premise Liability',
        amount: '$275,000.00',
        description:
            'An elderly woman suffered a fracture after being hit by electric scooter while she was shopping.',
    },
    {
        id: 19,
        type: 'Catastrophic Injury',
        subtype: 'Vehicle Accident',
        amount: '$266,936.79',
        description:
            'A man suffered soft tissue cervical spine injuries after being rear-ended.',
    },
    {
        id: 21,
        type: 'Catastrophic Injury',
        subtype: 'Electrocution',
        amount: '$250,000.00',
        description:
            'A man was electrocuted by a powerline that should have been deactivated.',
    },
    {
        id: 22,
        type: 'Catastrophic Injury',
        subtype: 'Aviation',
        amount: '$235,000.00',
        description:
            'A man suffered a mild traumatic brain injury after his employer provided him with unsafe equipment.',
    },
    {
        id: 23,
        type: 'Catastrophic Injury',
        subtype: 'Premise Liability',
        amount: '$200,000.00',
        description:
            'A woman suffered a fracture after tripping on a known unsafe condition at a business.',
    },
    {
        id: 24,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$175,000.00',
        description:
            'A man suffered soft tissue cervical spine injuries after being hit in a T-bone collision by another driver.',
    },
    {
        id: 26,
        type: 'Personal Injury',
        subtype: 'Premise Liability',
        amount: '$125,000.00',
        description:
            'An elderly woman suffered a fracture after tripping on a known unsafe condition at a business.',
    },
    {
        id: 27,
        type: 'Personal Injury',
        subtype: 'Premise Liability',
        amount: '$125,000.00',
        description:
            'An elderly woman suffered a fracture after an automatic door improperly closed on her at a business.',
    },
    {
        id: 28,
        type: 'Personal Injury',
        subtype: 'Negligent Supervision',
        amount: '$115,000.00',
        description:
            'An infant suffered minor burn injuries after a caretaker left her coffee unattended.',
    },
    {
        id: 30,
        type: 'Personal Injury',
        subtype: 'Negligent Security',
        amount: '$100,000.00',
        description:
            'A woman suffered injuries after being assaulted by an inmate at her place of business.',
    },
    {
        id: 32,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$100,000.00',
        description:
            'An elderly woman required elbow surgery after being hit by motor vehicle that violated her right of way.',
    },
    {
        id: 33,
        type: 'Personal Injury',
        subtype: 'Product Liability',
        amount: '$80,000.00',
        description:
            'A young man required hip surgery after falling through an improperly designed roof hatch.',
    },
    {
        id: 34,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$70,000.00',
        description:
            'An elderly woman suffered cervical spine injuries after being hit by a motor vehicle that failed to stop in time.',
    },
    {
        id: 52,
        type: 'Personal Injury',
        subtype: 'Premise Liability',
        amount: '$69,500.00',
        description:
            'An elderly man was injured when his chair collapsed at a resort, causing a back injury.',
    },
    {
        id: 35,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$55,000.00',
        description:
            'A man suffered cervical spine injuries after being hit by a motor vehicle that ran a red light.',
    },
    {
        id: 36,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$50,000.00',
        description:
            'A man suffered cervical spine injuries after being hit by a motor vehicle that violated his right of way.',
    },
    {
        id: 37,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$50,000.00',
        description:
            'A woman suffered cervical spine injuries after being hit by a motor vehicle that improperly switched lanes of traffic.',
    },
    {
        id: 38,
        type: 'Personal Injury',
        subtype: 'Premise Liability',
        amount: '$50,000.00',
        description:
            'An elderly woman fractured her knee after slipping on a wet substance at a shopping mall.',
    },
    {
        id: 62,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$45,000.00',
        description: 'A person was injured in an intersection by a driver that ran a stop sign.',
    },
    {
        id: 39,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$30,000.00',
        description:
            'A young man suffered cervical spine injuries after being hit by an impaired driver that ran a red light.',
    },
    {
        id: 40,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$25,000.00',
        description:
            'A young man suffered cervical spine injuries after another driver violated his right of way.',
    },
    {
        id: 41,
        type: 'Personal Injury',
        subtype: 'Premise Liability',
        amount: '$22,500.00',
        description:
            'An elderly woman fractured her foot after she was hit by a supermarket employee pushing a large cart.',
    },
    {
        id: 42,
        type: 'Personal Injury',
        subtype: 'Premise Liability',
        amount: '$22,500.00',
        description:
            'A woman was injured while shopping after a display stand collapsed hitting her head.',
    },
    {
        id: 44,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$20,000.00',
        description:
            'A young man suffered cervical spine injuries after another driver improperly merged into his lane of traffic.',
    },
    {
        id: 45,
        type: 'Personal Injury',
        subtype: 'Product Liability',
        amount: '$20,000.00',
        description:
            'A young man suffered an injury to his ear after his paper shredder exploded during use.',
    },
    {
        id: 63,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$17,500.00',
        description: 'A person was injured in an intersection by a driver that made an improper left-hand turn.',
    },
    {
        id: 46,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: '$17,500.00',
        description:
            'A woman suffered cervical spine injuries after being hit by a motor vehicle while she was walking in a crosswalk.',
    },
    {
        id: 48,
        type: 'Personal Injury',
        subtype: 'Vehicle Accident',
        amount: 'Defense verdict',
        description:
            'A young woman suffered a hand injury in a motor vehicle accident.',
    },
];

// Next ID: 70
